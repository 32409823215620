import * as React from 'react';
import {Snackbar ,Alert, Grid,} from '@mui/material';
import {useSelector, useDispatch, Provider} from 'react-redux';
import { setState,store,setSubTotal1,setDeductGrandTotal,setDeductTotal,setSubTotal2,  
  setDeductRentMortgage,
  setDeductRatesGovRent,
  setDeductMgtFee,
  setDeductSalariesTax,
  setDeductMPF,  
  setDeductChildCareExp,
  setDeductSchoolFee,  
  setDeductAvgMedicalExp,  
  setDeductMaintPaid,
  setDeductPersonalAllowance,
  setInit,
  setInit2,
} from './store/eCalcStore.js';
import './App.css';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import {ECalLandingFooter,ECalLandingHeader,ECalLanding} from './view/ECalLanding.js';
import {ECalLandingFooterDesktop,ECalLandingHeaderDesktop,ECalLandingDesktop} from './view/ECalLandingDesktop.js';
import {ECalApplicationFormBodyInfo} from './view/ECalApplicationFormInfo.js';
import {ECalApplicationFormBodyInfoDesktop} from './view/ECalApplicationFormInfoDesktop.js';
import {ECalApplicationFormBodyIncome} from './view/ECalApplicationFormIncome.js';
import {ECalApplicationFormBodyIncomeDesktop} from './view/ECalApplicationFormIncomeDesktop.js';
import {ECalApplicationFormBodyAsset} from './view/ECalApplicationFormAsset.js';
import {ECalApplicationFormBodyAssetDesktop} from './view/ECalApplicationFormAssetDesktop.js';
import {ECalApplicationFormBodyResult} from './view/ECalApplicationFormResult.js';
import {ECalApplicationFormBodyResultDesktop} from './view/ECalApplicationFormResultDesktop.js';
import {ECalcApplicationFormMonthlyDeduct} from './view/ECalcApplicationFormMonthlyDeduct.js';
import {ECalcApplicationFormMonthlyDeductDesktop} from './view/ECalcApplicationFormMonthlyDeductDesktop.js';
import i18n from "i18n-js";
import en from "./translate/en.json";
import tc from "./translate/tc.json";
import sc from "./translate/sc.json";
import  en_desktop from "./translate/en_desktop.json";
import  tc_desktop from "./translate/tc_desktop.json";
import sc_desktop from "./translate/sc_desktop.json";
import {isMobile} from './common/common-lib.js';
import axios from 'axios';
import moment from 'moment';
import {convFloat} from './view/ECalApplicationFormCommon.js';

const APIHost = window.APIHost

i18n.fallbacks = true;
i18n.locale = 'tc';

i18n.translations = {  ...en, ...tc,...sc ,
  ...en_desktop,...tc_desktop,...sc_desktop
};

function ECalApplicationForm(props){
  const [stepVal,setStepVal] = React.useState("info");
  const [alertOpen,setAlertOpen] = React.useState(false);
  const [alertMsg,setAlertMsg] = React.useState("");

  const householdAssetTotal =useSelector((state)=>state.ecalc.householdAssetTotal);    
  const householdMemberCount =useSelector((state)=>state.ecalc.householdMemberCount);  
  const householdIncomeAssets =useSelector((state)=>state.ecalc.householdIncomeAssets);
  const EstimatedItemCost = useSelector((state)=> state.ecalc.EstimatedItemCost);
  const isDrug =useSelector((state)=>state.ecalc.isDrug);
  const householdIncomeTotal =useSelector((state)=>state.ecalc.householdIncomeTotal);
  const DeductTotal = useSelector((state)=> state.ecalc.DeductTotal);
  const DeductPersonalAllowance = useSelector((state)=> state.ecalc.DeductPersonalAllowance);
  const recurrentApp  =useSelector((state)=>state.ecalc.recurrentApp);
  const _CCFMD =useSelector((state)=>state.ecalc._CCFMD);

  const checkinput = (prvState)=>{
    if(prvState ==="info"){
      if(EstimatedItemCost ===0){        
        setAlertMsg(props.i18n.t("msg_missingitemcost"));
        setAlertOpen(true);
      }  
    }
    
    if(prvState ==="income") {
        //validateRelationship
        let relquery = householdIncomeAssets.filter(item=>item.patient_rel==='').map(item=>{return {id:item.id,patient_rel:item.patient_rel}});        
        if(relquery.length>0){
          setAlertMsg(props.i18n.t("msg_missing_relation").replace('{0}',relquery[0].id));
          setAlertOpen(true);
          return false;
        }
        
        if(householdIncomeTotal ===0){
          setAlertMsg(props.i18n.t("msg_confirm_houlseholdincome"));
          setAlertOpen(true);
        }
    }
    if(prvState ==="asset") {  
      if(householdAssetTotal ===0){        
        setAlertMsg(props.i18n.t("msg_confirm_asset"));
        setAlertOpen(true);
      }   
    }
    if(prvState ==="monthlyDeduct"){
      if(DeductTotal ===0){        
        setAlertMsg(props.i18n.t("msg_confirm_expense"));
        setAlertOpen(true);
      }   
    }

    return true;
  };

  function renderBody(props){
    let ecalcState={isDrug,EstimatedItemCost,householdIncomeTotal,householdAssetTotal, householdMemberCount,DeductTotal,householdIncomeAssets, 
      _CCFMD, recurrentApp,DeductPersonalAllowance,};
    if(stepVal === "result"){
      return <ECalApplicationFormBodyResult refno={props.refno} calculateDeduct={props.calculateDeduct} ecalcState={ecalcState} onChange={(newVal)=>{if(newVal!==" ") setStepVal(newVal)}} stepVal={stepVal} enabledStep={props.enabledStep} i18n={props.i18n}/ >
    }
    if(stepVal === "monthlyDeduct"){
     return   <ECalcApplicationFormMonthlyDeduct ecalcState={ecalcState} calculateDeduct={props.calculateDeduct}  onChange={(newVal)=>{if(newVal!==" "&&checkinput(stepVal)) setStepVal(newVal)}} stepVal={stepVal}  enabledStep={props.enabledStep} onNext={()=>{props.onNext(5)}} onReset={props.onReset}  i18n={props.i18n}/> 
    }
    if(stepVal === "asset"){
        return <ECalApplicationFormBodyAsset ecalcState={ecalcState} calculateDeduct={props.calculateDeduct}  onChange={(newVal)=>{if(newVal!==" "&&checkinput(stepVal))  setStepVal(newVal)}} stepVal={stepVal} enabledStep={props.enabledStep} onNext={()=>{isDrug=='true'?props.onNext(4):props.onNext(5)}} onReset={props.onReset} i18n={props.i18n}/>
    }
    if(stepVal === "income"){
        return <ECalApplicationFormBodyIncome ecalcState={ecalcState} calculateDeduct={props.calculateDeduct}  onChange={(newVal)=>{if(newVal!==" "&&checkinput(stepVal)) setStepVal(newVal)}} stepVal={stepVal}  enabledStep={props.enabledStep} onNext={()=>{props.onNext(3)}} onReset={props.onReset} i18n={props.i18n}/> 
    }
    if(stepVal === "info"){
     return   <ECalApplicationFormBodyInfo ecalcState={ecalcState} calculateDeduct={props.calculateDeduct}  onChange={(newVal)=>{if(newVal!==" ") setStepVal(newVal)}} stepVal={stepVal}  enabledStep={props.enabledStep} onNext={()=>{props.onNext(2)}} i18n={props.i18n}  onReset={props.onReset} /> 
    }
    
  }
  return(
    <>
    <ECalLandingHeader i18n={i18n} onFontChange={(fontsize)=>{document.getElementById("root").style.zoom =fontsize}}/>
    {renderBody(props)}
    <Snackbar anchorOrigin={{ vertical:"top",horizontal:'center'}}open={alertOpen} autoHideDuration={6000} onClose={() => {setAlertOpen(false);}}>
      <Alert onClose={() => {setAlertOpen(false);}} severity="error">{alertMsg}</Alert>
    </Snackbar>
    <ECalLandingFooter i18n={props.i18n}/>
    </>
  )
}

function ECalApplicationFormDesktop(props){
  const [stepVal,setStepVal] = React.useState("info");
  const [alertOpen,setAlertOpen] = React.useState(false);
  const [alertMsg,setAlertMsg] = React.useState("");

  const householdAssetTotal =useSelector((state)=>state.ecalc.householdAssetTotal);    
  const householdMemberCount =useSelector((state)=>state.ecalc.householdMemberCount);  
  const householdIncomeAssets =useSelector((state)=>state.ecalc.householdIncomeAssets);
  const EstimatedItemCost = useSelector((state)=> state.ecalc.EstimatedItemCost);
  const isDrug =useSelector((state)=>state.ecalc.isDrug);
  const householdIncomeTotal =useSelector((state)=>state.ecalc.householdIncomeTotal);
  const DeductTotal = useSelector((state)=> state.ecalc.DeductTotal);
  const DeductPersonalAllowance = useSelector((state)=> state.ecalc.DeductPersonalAllowance);
  const recurrentApp  =useSelector((state)=>state.ecalc.recurrentApp);
  const _CCFMD =useSelector((state)=>state.ecalc._CCFMD);

  const checkinput = (prvState)=>{
    if(prvState ==="info"){
      if(EstimatedItemCost ===0){        
        setAlertMsg(props.i18n.t("msg_missingitemcost"));
        setAlertOpen(true);
        return false;
      }  
    }
  
    if(prvState ==="income") {
        //validateRelationship
        let relquery = householdIncomeAssets.filter(item=>item.patient_rel==='').map(item=>{return {id:item.id,patient_rel:item.patient_rel}});
        
        if(relquery.length>0){
          setAlertMsg(props.i18n.t("msg_missing_relation").replace('{0}',relquery[0].id));
          setAlertOpen(true);
          return false;
        }
        
        if(householdIncomeTotal ===0){
          setAlertMsg(props.i18n.t("msg_confirm_houlseholdincome"));
          setAlertOpen(true);
        }
    }
    if(prvState ==="asset") {  
      if(householdAssetTotal ===0){
        
        setAlertMsg(props.i18n.t("msg_confirm_asset"));
        setAlertOpen(true);
      }   
    }
    if(prvState ==="monthlyDeduct"){
      if(DeductTotal ===0){        
        setAlertMsg(props.i18n.t("msg_confirm_expense"));
        setAlertOpen(true);
      }   
    }
    return true;
  }
  let ecalcState={isDrug,_CCFMD,recurrentApp,EstimatedItemCost,householdIncomeTotal,householdAssetTotal,DeductTotal, householdMemberCount,householdIncomeAssets,
    DeductPersonalAllowance, };

  const lang = useSelector((state)=> state.config.lang);
  var mthly_hh_income = useSelector((state)=>state.ecalc.DeductRatesGovRent);
  const mthly_disp_capital =useSelector((state)=>state.ecalc.SubTotal1);
  //drug:
 const rent_mortgage = useSelector((state)=>state.ecalc.DeductRentMortgage);
 const rates_gov_rent = useSelector((state)=>state.ecalc.DeductRatesGovRent);
 const mgt_fee = useSelector((state)=>state.ecalc.DeductMgtFee);
 const ttl_exp_fm_ppty = useSelector((state)=>state.ecalc.SubTotal1);

 const DeductSalariesTax =useSelector((state)=>state.ecalc.DeductSalariesTax??0);
 const DeductMPF   =useSelector((state)=>state.ecalc.DeductMPF??0);
 const DeductChildCareExp =useSelector((state)=>state.ecalc.DeductChildCareExp??0);
 const DeductSchoolFee   =useSelector((state)=>state.ecalc.DeductSchoolFee??0);
 const DeductAvgMedicalExp   =useSelector((state)=>state.ecalc.DeductAvgMedicalExp??0);
 const DeductMaintPaid =useSelector((state)=>state.ecalc.DeductMaintPaid??0);
 
 const subTotal3 = DeductSalariesTax+DeductMPF+DeductChildCareExp+DeductSchoolFee+DeductAvgMedicalExp+DeductMaintPaid+DeductPersonalAllowance;
 //const showPart2 = _CCFMD=="true";
const i18n = props.i18n;
  const printPreview =(props)=>{
    console.log(props);
        const relationStr =[                        
                        { value:"Patient" , text: i18n.t("tab2_patient") } ,
                        { value:"Parent" , text: i18n.t("cboRelation_parent") } ,
                        { value:"Child" , text:i18n.t("cboRelation_child")} ,
                        { value:"Spouse" , text:i18n.t("cboRelation_spouse")} ,
                        { value:"Sibling" , text:i18n.t("cboRelation_brosis")} ,
                        { value:"Others" , text:i18n.t("cboRelation_other")} ,
                      ]
        const item_type = isDrug=="true"? i18n.t("tab1_label_radDrugItem") : i18n.t("tab1_label_radNonDrugItem")                       
          
        var total_cont_amt =(props.total_cont_amt??0).toLocaleString('en-US',{style:"currency", currency:"USD",minimumFractionDigits: 0});
        const showPart2 = !props.showPart2;
        const sf_fix_cont_amt =props.sf_fix_cont_amt;

        mthly_hh_income = mthly_hh_income.toLocaleString('en-US',{style:"currency", currency:"USD",minimumFractionDigits: 0})??0;
        var msg_income_catg = props.msg_income_catg??"";
        
        var asset_catg_sys = props.asset_catg_sys??"";//lbl_tab5_nondrug_asset_result
        var compare_allowance_flag = props.compare_allowance_flag??"";//lbl_tab5_nondrug_asset_allowance
        var total_cont_ratio = props.total_cont_ratio??"0";
        var total_cont = total_cont_amt;
        //drug:
        var sf_cont_assessment = props.drug_adfr_times_cont_ratio?i18n.t("tab5_drug_patient_fix_cont_amt_or_item_cost"):"";
        var sf_cont_assessment2 = props.showDrug_no_item_cost?i18n.t("tab5_drug_no_item_cost"):"";
        var subsidy_amt = props.showDrug_no_item_cost?"-":(props.Summary_SubsidyAmount??0);
        if(isDrug!="true" )
          subsidy_amt =(props.Summary_SubsidyAmount??0);
        
        
        var lumpsum_deduct = (props.LumpSumDeduct??0);
        var expr1 ="X 12 +";
        var adfr_amt =props.ADFR_Result??0;
        var expr2 = (props.ADFR_Result??0)>0?"":"($0)";
        var actual_adfr_amt = adfr_amt;
        var sf_cont_ratio = props.sf_cont_ratio;
        var sf_patient_cont_amt = props.ADFR_ContRatio_Result
        var Actual_lumpsum_deduct =  (props.Actual_lumpsum_deduct??0)
        if((props.income_factor??100) != 100)
          expr1 = "X "+(props.income_factor??100)+"% "+"X 12 +";
        var sfecal_assessment = DeductTotal;
        let tmpincomeAssets=householdIncomeAssets.map((item)=>{
            return {                                    
                    "id": item.id,
                    "patient_rel": relationStr.filter(mitem=>item.patient_rel===mitem.value).map(mitem=>mitem.text)[0],
                    "salary": item.salary.toLocaleString('en-US',{style:"currency", currency:"USD",minimumFractionDigits: 0}),
                    "allowance": item.allowance.toLocaleString('en-US',{style:"currency", currency:"USD",minimumFractionDigits: 0}),
                    "double_pay": item.double_pay.toLocaleString('en-US',{style:"currency", currency:"USD",minimumFractionDigits: 0}),
                    "benefit_in_kind": item.benefit_in_kind.toLocaleString('en-US',{style:"currency", currency:"USD",minimumFractionDigits: 0}),
                    "pension": item.pension.toLocaleString('en-US',{style:"currency", currency:"USD",minimumFractionDigits: 0}),
                    "rental_income": item.rental_income.toLocaleString('en-US',{style:"currency", currency:"USD",minimumFractionDigits: 0}),
                    "sponsor": item.sponsor.toLocaleString('en-US',{style:"currency", currency:"USD",minimumFractionDigits: 0}),
                    "other_income": item.other_income.toLocaleString('en-US',{style:"currency", currency:"USD",minimumFractionDigits: 0}),                                
                    "subTotal": item.subTotal.toLocaleString('en-US',{style:"currency", currency:"USD",minimumFractionDigits: 0}),
                    
                    "cash": item.cash.toLocaleString('en-US',{style:"currency", currency:"USD",minimumFractionDigits: 0}),
                    "saving": item.saving.toLocaleString('en-US',{style:"currency", currency:"USD",minimumFractionDigits: 0}),
                    "investment": item.investment.toLocaleString('en-US',{style:"currency", currency:"USD",minimumFractionDigits: 0}),
                    "insurance": item.insurance.toLocaleString('en-US',{style:"currency", currency:"USD",minimumFractionDigits: 0}),
                    "property": item.property.toLocaleString('en-US',{style:"currency", currency:"USD",minimumFractionDigits: 0}),
                    "carpark": item.carpark.toLocaleString('en-US',{style:"currency", currency:"USD",minimumFractionDigits: 0}),
                    "land": item.land.toLocaleString('en-US',{style:"currency", currency:"USD",minimumFractionDigits: 0}),
                    "other": item.other.toLocaleString('en-US',{style:"currency", currency:"USD",minimumFractionDigits: 0}),
                    "subTotal2": item.subTotal2.toLocaleString('en-US',{style:"currency", currency:"USD",minimumFractionDigits: 0})
            }});
                    /*open preview*/   
                    axios({
                        method: 'post',
                        url: APIHost+'/printPreview',
                        data: {
                            "isDrug":isDrug,
                            "hhSize": householdMemberCount,
                            "item_type":item_type,
                            "householdAssetTotal": householdAssetTotal.toLocaleString('en-US',{style:"currency", currency:"USD",minimumFractionDigits: 0}),
                            "householdIncomeTotal":householdIncomeTotal.toLocaleString('en-US',{style:"currency", currency:"USD",minimumFractionDigits: 0}),
                            "householdIncomeAssets":tmpincomeAssets,
                            "assessmentDate":moment().format("YYYY-MM-DD HH:mm:ss") ,
                            "lang":lang,
                            "total_cont_amt":isFinite(total_cont_amt)?parseFloat(total_cont_amt).toLocaleString('en-US',{style:"currency", currency:"USD",minimumFractionDigits: 0}):total_cont_amt,
                            "subsidy_amt":subsidy_amt.toLocaleString('en-US',{style:"currency", currency:"USD",minimumFractionDigits: 0}),
                            "item_cost":isFinite(EstimatedItemCost)?parseFloat(EstimatedItemCost).toLocaleString('en-US',{style:"currency", currency:"USD",minimumFractionDigits: 0}):EstimatedItemCost,
                            "mthly_hh_income":mthly_hh_income.toLocaleString('en-US',{style:"currency", currency:"USD",minimumFractionDigits: 0}),
                            "income_catg_sys":msg_income_catg,
                            //"mthly_disp_capital":mthly_disp_capital.toLocaleString('en-US',{style:"currency", currency:"USD",minimumFractionDigits: 0}),
                            "mthly_disp_capital": householdAssetTotal.toLocaleString('en-US',{style:"currency", currency:"USD",minimumFractionDigits: 0}),
                            "asset_catg_sys":asset_catg_sys,
                            "compare_allowance_flag":compare_allowance_flag,
                            'total_cont_ratio':total_cont_ratio,
                            "total_cont":total_cont.toLocaleString('en-US',{style:"currency", currency:"USD",minimumFractionDigits: 0}),
                            //drug:
                            "rent_mortgage":rent_mortgage.toLocaleString('en-US',{style:"currency", currency:"USD",minimumFractionDigits: 0}),
                            "rates_gov_rent":rates_gov_rent.toLocaleString('en-US',{style:"currency", currency:"USD",minimumFractionDigits: 0}),
                            "mgt_fee":mgt_fee.toLocaleString('en-US',{style:"currency", currency:"USD",minimumFractionDigits: 0}),
                            "ttl_exp_fm_ppty":ttl_exp_fm_ppty.toLocaleString('en-US',{style:"currency", currency:"USD",minimumFractionDigits: 0}),
                            "salaries_tax":DeductSalariesTax.toLocaleString('en-US',{style:"currency", currency:"USD",minimumFractionDigits: 0}),
		                        "pf_mpf":DeductMPF.toLocaleString('en-US',{style:"currency", currency:"USD",minimumFractionDigits: 0}),
		                        "child_care_exp":DeductChildCareExp.toLocaleString('en-US',{style:"currency", currency:"USD",minimumFractionDigits: 0}),
		                        "school_fee":DeductSchoolFee.toLocaleString('en-US',{style:"currency", currency:"USD",minimumFractionDigits: 0}),
		                        "avg_medical_exp":DeductAvgMedicalExp.toLocaleString('en-US',{style:"currency", currency:"USD",minimumFractionDigits: 0}),
                            "maint_paid":DeductMaintPaid.toLocaleString('en-US',{style:"currency", currency:"USD",minimumFractionDigits: 0}),
		                        "personal_allowance":DeductPersonalAllowance.toLocaleString('en-US',{style:"currency", currency:"USD",minimumFractionDigits: 0}),
                            "subTotal3":subTotal3.toLocaleString('en-US',{style:"currency", currency:"USD",minimumFractionDigits: 0}),
                            "sf_cont_assessment":sf_cont_assessment,
                            "sf_cont_assessment2":sf_cont_assessment2,
                            //"subsidy_amt":subsidy_amt,
                            "mthly_ad":subTotal3.toLocaleString('en-US',{style:"currency", currency:"USD",minimumFractionDigits: 0}),
                            "lumpsum_deduct":lumpsum_deduct.toLocaleString('en-US',{style:"currency", currency:"USD",minimumFractionDigits: 0}),
                            "expr1":expr1,
                            "Actual_lumpsum_deduct":Actual_lumpsum_deduct.toLocaleString('en-US',{style:"currency", currency:"USD",minimumFractionDigits: 0}),
                            "adfr_amt":adfr_amt.toLocaleString('en-US',{style:"currency", currency:"USD",minimumFractionDigits: 0}),
                            "expr2":expr2,
                            "showPart2":showPart2,
                            "actual_adfr_amt":actual_adfr_amt.toLocaleString('en-US',{style:"currency", currency:"USD",minimumFractionDigits: 0}),
                            "sf_cont_ratio":sf_cont_ratio,
                            "sf_patient_cont_amt":isFinite(sf_patient_cont_amt)?sf_patient_cont_amt.toLocaleString('en-US',{style:"currency", currency:"USD",minimumFractionDigits: 0}):sf_patient_cont_amt,
                            "sf_fix_cont_amt":isFinite(sf_fix_cont_amt)?sf_fix_cont_amt.toLocaleString('en-US',{style:"currency", currency:"USD",minimumFractionDigits: 0}):sf_fix_cont_amt,
                            "sfecal_assessment":sfecal_assessment.toLocaleString('en-US',{style:"currency", currency:"USD",minimumFractionDigits: 0})
                        },
                        responseType: 'blob',
                      }).then((resp)=>{
                        var blob = new Blob([resp.data],{ type: 'application/pdf' });
                        var url = URL.createObjectURL(blob);
                        window.open(url, '__blank');
                       });   
  }  

  let Summary_PatientAmount =  0;
  let lbl_drug_adfr_times_cont = false;
  let lbl_drug_no_item_cost = false;
  let Summary_SubsidyAmount  = 0;
  let DrugSummary_LumpSumDeduct = 0;
  let ADFR_Result = 0;
  let ADFR_ContRatio_Result = "";
  let ADFR_LumpSumDeduct = 0;
  let ADFR_IncomeFactor  = 80;
  let trSFFixAmt = false;
  let FixAmt_Result = "";
  let ContRatio_Result = "";

  let Msg_income_catg = "";
  let lbl_tab5_nondrug_asset_result = "";
  let lbl_tab5_nondrug_asset_allowance = "";
  let NonDrugSummary_ContRatio = 0;
  function isEmpty(val){
    return val == null
}
  const printPreviewPre =(props)=>{
   
          if(isDrug =='true'){    
                axios({
                  method: 'post',
                  url: APIHost+'/DRUGASSESSMENT',
                  data: {
                      drug_cost:EstimatedItemCost,
                      hhm_size:householdMemberCount,
                      hh_income:householdIncomeTotal,
                      expenditure:DeductTotal,
                      income_factor:(recurrentApp=="true")&&(householdIncomeTotal>DeductTotal)?80:100,
                      asset:householdAssetTotal
                  },
                
                }).then((resp)=>{
                    // console.log(resp.data);
                    if(resp.data.retCde != '0'){
                        if(!isEmpty(resp.data.Message)){
                            var error_msg = resp.data.Message;
                            return error_msg;
                        }
                    }
                    
                    let is_ued = false
                    let drug_cost = resp.data.result.drug_cost;
                    let patient_cont_amt = convFloat(resp.data.result.patient_cont_amt);
                    let sf_subsidy_amt = convFloat(resp.data.result.sf_subsidy_amt);
                    let income_factor = convFloat(resp.data.result.income_factor);
                    let adfr = convFloat(resp.data.result.adfr);
                    let sf_cont_ratio = convFloat(resp.data.result.sf_cont_ratio);
                    let actual_adfr = convFloat(resp.data.result.actual_adfr);
                    let sf_fix_cont_amt = convFloat(resp.data.result.sf_fix_cont_amt);
                    let lumpsum_dect = convFloat(resp.data.result.lumpsum_dect);
                    let actual_lumpsum_dect = convFloat(resp.data.result.actual_lumpsum_dect);
                    ADFR_IncomeFactor = convFloat(resp.data.result.income_factor);
                    DrugSummary_LumpSumDeduct = convFloat(lumpsum_dect);
                    ADFR_LumpSumDeduct = actual_lumpsum_dect;

                     if (drug_cost > 1000000 && patient_cont_amt > 1000000) {
                        is_ued = true;
                        patient_cont_amt = 1000000;
                        sf_subsidy_amt = drug_cost - patient_cont_amt;
                    }
                    Summary_PatientAmount = patient_cont_amt ;
                    if (drug_cost > 0) {     
                      lbl_drug_no_item_cost = false;       
                      Summary_SubsidyAmount = sf_subsidy_amt
                    } else {
                      lbl_drug_no_item_cost = true;
                      Summary_SubsidyAmount = ''
                    }
                    ADFR_Result = adfr;
                    if (sf_cont_ratio !="") { 
                      if (patient_cont_amt == drug_cost) {
                        lbl_drug_adfr_times_cont = false;                        
                     } else {                
                        lbl_drug_adfr_times_cont = true;                        
                     }                               
                      trSFFixAmt = false;
                      ContRatio_Result = sf_cont_ratio + '%';                     
                      var _temp_result = actual_adfr * sf_cont_ratio / 100.0;                      
                      if (is_ued)
                          _temp_result = 1000000;
                      ADFR_ContRatio_Result = Math.round(_temp_result);   
                  } else {
                      //sf fix amt
                      FixAmt_Result = sf_fix_cont_amt;
                      trSFFixAmt = true;
                  }
                    printPreview({                            
                      total_cont_amt:patient_cont_amt,
                      drug_adfr_times_cont_ratio:lbl_drug_adfr_times_cont,
                      showDrug_no_item_cost:lbl_drug_no_item_cost,
                      Summary_SubsidyAmount:Summary_SubsidyAmount,
                      LumpSumDeduct:DrugSummary_LumpSumDeduct,
                      ADFR_Result:ADFR_Result,
                      ADFR_ContRatio_Result:ADFR_ContRatio_Result,
                      Actual_lumpsum_deduct: ADFR_LumpSumDeduct,
                      income_factor:ADFR_IncomeFactor,
                      showPart2:trSFFixAmt,
                      sf_fix_cont_amt:FixAmt_Result,
                      sf_cont_ratio:ContRatio_Result,
                  });  
                  return ""
                }).catch((err)=>{
                  console.log(err);                          
                  return "Connection error"
                })                    
                
          }else{
            
            axios({
              method: 'post',
              url: APIHost+'/NONDRUGASSESSMENT',
              data: {
                  program_type:(_CCFMD=='true')?"CCF-MD" : "SF",
                  item_cost:EstimatedItemCost,
                  hhm_size:householdMemberCount,
                  hh_income:householdIncomeTotal,
                  asset:householdAssetTotal,
              },
             
            }).then((resp)=>{
              console.log(resp.data);
              
              if(resp.data.retCde != '0'){
                  if(!isEmpty(resp.data.Message)){
                      var error_msg = resp.data.Message;
                      return 'Fail Retrieve Data\n' + error_msg;
                  }
              }
  
              let mean_test_ratio = resp.data.result.mean_test_ratio;
              let compare_allowance_flag = resp.data.result.compare_allowance_flag;
              let income_catg = resp.data.result.income_catg;
              let asset = resp.data.result.asset;
              let item_cost = resp.data.result.item_cost;
              let total_cont_ratio = resp.data.result.total_cont_ratio;
              let asset_catg = resp.data.result.asset_catg;
              let total_cont_amt = resp.data.result.total_cont_amt;
              let hh_income= householdIncomeTotal;
              let subsidy_amt= resp.data.result.subsidy_amt;
              Summary_PatientAmount = convFloat(total_cont_amt);
              Summary_SubsidyAmount = convFloat(subsidy_amt);
  
              var msg_mean_test_ratio = i18n.t("tab5_nondrug_income_mean_test_ratio");
              if (mean_test_ratio == '1') {
                  msg_mean_test_ratio = '';
              } else {
                  msg_mean_test_ratio = msg_mean_test_ratio.replace('{0}', mean_test_ratio);
              }          
              if (income_catg == 3) {
                  Msg_income_catg = (i18n.t("tab5_nondrug_income_above_mmdhi").replace('{0}', msg_mean_test_ratio));
                  
              } else if (income_catg == 2) {                 
                  if (_CCFMD=='true') {     //  CCF-MD
                      var msg_allowance = "";
                      if (compare_allowance_flag == 'L') {
                          msg_allowance = i18n.t("tab5_nondrug_asset_allowance_lower");
                      } else if (compare_allowance_flag == 'U') {
                          msg_allowance = i18n.t("tab5_nondrug_asset_allowance_upper");
                      }
                      lbl_tab5_nondrug_asset_allowance = msg_allowance;
                  } else {
                      lbl_tab5_nondrug_asset_allowance = "";
                  }
              } 
              else if (income_catg == 1) {
                  Msg_income_catg = (i18n.t("tab5_nondrug_income_below_mmdhi").replace('{0}', msg_mean_test_ratio));                  
                  if (_CCFMD=='true') {     //  CCF-MD
                      var msg_allowance = "";
                      if (compare_allowance_flag == 'L') {
                          msg_allowance = i18n.t("tab5_nondrug_asset_allowance_lower");
                      } else if (compare_allowance_flag == 'U') {
                          msg_allowance = i18n.t("tab5_nondrug_asset_allowance_upper");
                      }                    
                      lbl_tab5_nondrug_asset_allowance = msg_allowance;
                  } else {                    
                      lbl_tab5_nondrug_asset_allowance = "";
                  }
              }
      
              lbl_tab5_nondrug_asset_result = 'N/A';
              if (asset_catg == 1) {
                lbl_tab5_nondrug_asset_result = i18n.t("tab5_nondrug_asset_below_x2");                                  
              } 
              else if (asset_catg == 2) {
                  if (_CCFMD=='true') {     //  CCF-MD
                      var msg = props.i18n.t("tab5_nondrug_asset_x2_to_x225");
                      msg = msg.replace('{0}', 2);
                      msg = msg.replace('{1}', 3);                    
                      lbl_tab5_nondrug_asset_result = msg;
                  } else {
                      var factor = asset / item_cost;
                      var upper = '', lower = '';
      
                      if (factor > 2 && factor < 2.25) {
                          upper = 2.25;
                          lower = 2;
      
                          var msg = props.i18n.t("tab5_nondrug_asset_x2_to_x225");
                          msg = msg.replace('{0}', lower);
                          msg = msg.replace('{1}', upper);
                          lbl_tab5_nondrug_asset_result = msg;    
                      } else if (factor >= 2.25 && factor < 2.5) {
                          upper = 2.5;
                          lower = 2.25;
      
                          var msg = props.i18n.t("tab5_nondrug_asset_x225_to_x3");
                          msg = msg.replace('{0}', lower);
                          msg = msg.replace('{1}', upper);
                          lbl_tab5_nondrug_asset_result = msg;
      
                      } else if (factor >= 2.5 && factor < 2.75) {
                          upper = 2.75;
                          lower = 2.5;
      
                          var msg = props.i18n.t("tab5_nondrug_asset_x2_to_x225");
                          msg = msg.replace('{0}', lower);
                          msg = msg.replace('{1}', upper);
                          lbl_tab5_nondrug_asset_result = msg;
                      } else if (factor >= 2.75 && factor < 3) {
                          upper = 3;
                          lower = 2.75;
      
                          var msg = props.i18n.t("tab5_nondrug_asset_x2_to_x225");
                          msg = msg.replace('{0}', lower);
                          msg = msg.replace('{1}', upper);
                          lbl_tab5_nondrug_asset_result = msg;
                      } else if (factor == 3) {
                          var msg = props.i18n.t("tab5_nondrug_asset_above_x3");    
                          lbl_tab5_nondrug_asset_result = msg;
                      }
                  }    
               } 
              else if (asset_catg == 3) {
                lbl_tab5_nondrug_asset_result = i18n.t("tab5_nondrug_asset_above_x3");                 
              }
              if (_CCFMD=='true') {  
                               
              }else{
                NonDrugSummary_ContRatio = total_cont_ratio;
              }

              printPreview({
                total_cont_amt:Summary_PatientAmount,
                msg_income_catg:Msg_income_catg,
                asset_catg_sys:lbl_tab5_nondrug_asset_result,
                compare_allowance_flag:lbl_tab5_nondrug_asset_allowance,
                total_cont_ratio:NonDrugSummary_ContRatio,
                Summary_SubsidyAmount:Summary_SubsidyAmount,
                showPart2:trSFFixAmt,
                sf_fix_cont_amt:FixAmt_Result,
                sf_cont_ratio:ContRatio_Result,
              }); 
  
            }).catch((err)=>{
              console.log(err);
              return "Connection error";
            })
            

          }                        
                                   
       
  }  

  function renderBody(props){
    if(stepVal === "result"){
      return <ECalApplicationFormBodyResultDesktop refno={props.refno} printPreview={printPreview} calculateDeduct={props.calculateDeduct}  ecalcState={ecalcState} onChange={(newVal)=>{if(newVal!==" ") setStepVal(newVal)}} stepVal={stepVal} enabledStep={props.enabledStep} i18n={props.i18n}/ >
    }
    if(stepVal === "monthlyDeduct"){
     return   <ECalcApplicationFormMonthlyDeductDesktop  printPreviewPre={printPreviewPre} calculateDeduct={props.calculateDeduct}ecalcState={ecalcState} onChange={(newVal)=>{if(newVal!==" "&&checkinput(stepVal)) setStepVal(newVal)}} stepVal={stepVal}  enabledStep={props.enabledStep} onNext={()=>{props.onNext(5)}} i18n={props.i18n} onReset={props.onReset} /> 
    }
    if(stepVal === "asset"){
        return <ECalApplicationFormBodyAssetDesktop  printPreviewPre={printPreviewPre} calculateDeduct={props.calculateDeduct}ecalcState={ecalcState} onChange={(newVal)=>{if(newVal!==" "&&checkinput(stepVal))  setStepVal(newVal)}} stepVal={stepVal} enabledStep={props.enabledStep} onNext={()=>{ isDrug=='true'?props.onNext(5):props.onNext(4)}} onReset={props.onReset} i18n={props.i18n}/>
    }
    if(stepVal === "income"){
        return <ECalApplicationFormBodyIncomeDesktop   printPreviewPre={printPreviewPre} calculateDeduct={props.calculateDeduct}ecalcState={ecalcState} onChange={(newVal)=>{if(newVal!==" "&&checkinput(stepVal)) setStepVal(newVal)}} stepVal={stepVal}  enabledStep={props.enabledStep} onNext={()=>{props.onNext(3)}} onReset={props.onReset} i18n={props.i18n}/> 
    }
    if(stepVal === "info"){
     return   <ECalApplicationFormBodyInfoDesktop   printPreviewPre={printPreviewPre} calculateDeduct={props.calculateDeduct}ecalcState={ecalcState} onChange={(newVal)=>{if(newVal!==" ") setStepVal(newVal)}} stepVal={stepVal}  enabledStep={props.enabledStep} onNext={()=>{props.onNext(2)}} onNext1={()=>{props.onNext(1)}} i18n={props.i18n} onReset={props.onReset}/> 
    }
    
    
  }
  return(
    <>
    <ECalLandingHeaderDesktop i18n={i18n} onFontChange={(fontsize)=>{document.getElementById("root").style.zoom =fontsize}}/>
    {renderBody(props)}
    <Snackbar anchorOrigin={{ vertical:"top",horizontal:'center'}}open={alertOpen} autoHideDuration={6000} onClose={() => {setAlertOpen(false);}}>
      <Alert onClose={() => {setAlertOpen(false);}} severity="error">{alertMsg}</Alert>
    </Snackbar>
    <ECalLandingFooterDesktop i18n={props.i18n}/>
    </>
  )
}

function AppBody(){
  const [refno,setRefno] = React.useState('');  
  const lang = useSelector((state)=> state.config.lang);
  i18n.locale =useSelector((state)=>state.config.lang);
  const [agreeChecked,setAgreeChecked] = React.useState(false);
  const [enabledStep,setEnabledStep] = React.useState(1);
  const theme = createTheme({
                              typography: {
                                // Tell MUI what the font-size on the html element is.
                                FontSize: 9,
                              },
                            });
   //input
   const isDrug = null;
   const DeductTotal =0;
   const householdAssetTotal =0;
   const householdIncomeTotal=0;
   const householdMemberCount = 1;  
   const EstimatedItemCost=0; 
   
   const householdIncomeAssets = [
     {
       id:0,
       patient_rel:'Patient',
      
       salary:0,
       allowance:0,
       double_pay:0,
       benefit_in_kind:0,
       pension:0,
       rental_income:0,
       sponsor:0,
       other_income:0,
       subTotal:0,

       cash:0,
       saving:0,
       investment:0,
       insurance:0,
       property:0,
       carpark:0,
       land:0,
       other:0,
       subTotal2:0,
     }
   ];
   const recurrentApp = null;
   const _CCFMD = null;
   const dispatch = useDispatch()

   const DeductRentMortgage  =useSelector((state)=>state.ecalc.DeductRentMortgage);
   const DeductRatesGovRent  =useSelector((state)=>state.ecalc.DeductRatesGovRent);
   const DeductMgtFee  =useSelector((state)=>state.ecalc.DeductMgtFee);
   
   const DeductSalariesTax =useSelector((state)=>state.ecalc.DeductSalariesTax);
   const DeductMPF   =useSelector((state)=>state.ecalc.DeductMPF);
   const DeductChildCareExp =useSelector((state)=>state.ecalc.DeductChildCareExp);
   const DeductSchoolFee   =useSelector((state)=>state.ecalc.DeductSchoolFee);
   const DeductAvgMedicalExp   =useSelector((state)=>state.ecalc.DeductAvgMedicalExp);
   const DeductMaintPaid =useSelector((state)=>state.ecalc.DeductMaintPaid);
   const DeductPersonalAllowance =useSelector((state)=>state.ecalc.DeductPersonalAllowance);
   
   const HouseholdIncomeTotal =useSelector((state)=>state.ecalc.householdIncomeTotal);
   
   
   const calculateDeduct = (hOutOfRange)=>{    
    var MAX_INTEGER = 922337203685477;

    var SubTotal1 = 0;
    SubTotal1 = DeductRentMortgage+DeductRatesGovRent+DeductMgtFee;
    var HouseholdIncomeTotal_half = Math.ceil( HouseholdIncomeTotal/2.0);
    if(SubTotal1 > HouseholdIncomeTotal_half){
      SubTotal1 = HouseholdIncomeTotal_half
    }
    
    var SubTotal2 = 0;
    SubTotal2 = DeductSalariesTax+DeductMPF + DeductChildCareExp+DeductSchoolFee + DeductAvgMedicalExp + DeductMaintPaid + DeductPersonalAllowance;
    
    dispatch(setSubTotal2(SubTotal2));
  
    var GrantTotal = SubTotal1 + SubTotal2;
    if(GrantTotal > MAX_INTEGER){
      hOutOfRange();
    }
    
  
    dispatch(setSubTotal1(SubTotal1));
    dispatch(setDeductGrandTotal(GrantTotal));
    dispatch(setDeductTotal(GrantTotal));
   }

  if(agreeChecked !== true){
    return (
        <ThemeProvider theme={theme}>
          <ECalLanding calculateDeduct={calculateDeduct} onAgree={(_refno)=>{
            setRefno(_refno);
            setAgreeChecked(true);
            setEnabledStep(1);
            
            dispatch(setState({isDrug ,_CCFMD,recurrentApp,EstimatedItemCost,householdIncomeTotal,householdAssetTotal,householdIncomeAssets,householdMemberCount,DeductTotal,DeductPersonalAllowance,
              refNo:_refno}));
              // console.log("onAgree:",{isDrug ,_CCFMD,recurrentApp,EstimatedItemCost,householdIncomeTotal,householdAssetTotal,householdIncomeAssets,householdMemberCount,DeductTotal,DeductPersonalAllowance,
              //   refNo:_refno})
              dispatch(setSubTotal2(0));
              dispatch(setDeductRentMortgage(0));
              dispatch(setDeductRatesGovRent(0));
              dispatch(setDeductMgtFee(0));
              dispatch(setDeductSalariesTax(0));
              dispatch(setDeductMPF(0));
              dispatch(setDeductChildCareExp(0));
              dispatch(setDeductSchoolFee(0));
              dispatch(setDeductAvgMedicalExp(0));
              dispatch(setDeductMaintPaid(0));
              dispatch(setDeductPersonalAllowance(0));  
              dispatch(setInit(true));
              dispatch(setInit2(true));
          }}  i18n={i18n}/>    
        </ThemeProvider>
    )}else{
      return (
        <ThemeProvider theme={theme}>
          <ECalApplicationForm calculateDeduct={calculateDeduct}  lang={lang} refno={refno} enabledStep={enabledStep} onNext={(step)=>{setEnabledStep(step)}} onReset={()=>{
            const isDrug="true";
            const DeductTotal =0;
            const householdAssetTotal =0;
            const householdIncomeTotal=0;
            const householdMemberCount = 1;
            const EstimatedItemCost=0;
            
            const householdIncomeAssets = [
              {
                id:0,
                patient_rel:'Patient',
                
                salary:0,
                allowance:0,
                double_pay:0,
                benefit_in_kind:0,
                pension:0,
                rental_income:0,
                sponsor:0,
                other_income:0,
                subTotal:0,

                cash:0,
                saving:0,
                investment:0,
                insurance:0,
                property:0,
                carpark:0,
                land:0,
                other:0,
                subTotal2:0,
              }
            ];
            const recurrentApp = null;
            const _CCFMD = null;
            dispatch(setState({isDrug ,_CCFMD,recurrentApp,EstimatedItemCost,householdIncomeTotal,householdAssetTotal,householdIncomeAssets,householdMemberCount,DeductTotal,DeductPersonalAllowance,
              refNo:refno}));
              dispatch(setSubTotal2(0));
              dispatch(setDeductRentMortgage(0));
              dispatch(setDeductRatesGovRent(0));
              dispatch(setDeductMgtFee(0));
              dispatch(setDeductSalariesTax(0));
              dispatch(setDeductMPF(0));
              dispatch(setDeductChildCareExp(0));
              dispatch(setDeductSchoolFee(0));
              dispatch(setDeductAvgMedicalExp(0));
              dispatch(setDeductMaintPaid(0));
              //dispatch(setDeductPersonalAllowance(0));
          }} i18n={i18n}/>
        </ThemeProvider>
      )
    };
}

function AppBodyDesktop(){
  const langMap ={ en:'en_desktop',sc:'sc_desktop',tc:'tc_desktop',}
  const [refno,setRefno] = React.useState('');  
  const lang = useSelector((state)=> state.config.lang);
  
  
  i18n.locale =langMap[useSelector((state)=>state.config.lang)];
  const [agreeChecked,setAgreeChecked] = React.useState(false);
  const [enabledStep,setEnabledStep] = React.useState(1);
  
   //input
   
   const DeductTotal =0;
   const householdAssetTotal =0;
   const householdIncomeTotal=0;
   const householdMemberCount = 1;
   const EstimatedItemCost=0;
   
   const householdIncomeAssets = [
     {
       id:0,
       patient_rel:'Patient',
      
       salary:0,
       allowance:0,
       double_pay:0,
       benefit_in_kind:0,
       pension:0,
       rental_income:0,
       sponsor:0,
       other_income:0,
       subTotal:0,

       cash:0,
       saving:0,
       investment:0,
       insurance:0,
       property:0,
       carpark:0,
       land:0,
       other:0,
       subTotal2:0,
     }
   ];
   const recurrentApp = null;
   const _CCFMD = null;
   const dispatch = useDispatch()


    const DeductRentMortgage  =useSelector((state)=>state.ecalc.DeductRentMortgage??0);
    const DeductRatesGovRent  =useSelector((state)=>state.ecalc.DeductRatesGovRent??0);
    const DeductMgtFee  =useSelector((state)=>state.ecalc.DeductMgtFee??0);

    const DeductSalariesTax =useSelector((state)=>state.ecalc.DeductSalariesTax??0);
    const DeductMPF   =useSelector((state)=>state.ecalc.DeductMPF??0);
    const DeductChildCareExp =useSelector((state)=>state.ecalc.DeductChildCareExp??0);
    const DeductSchoolFee   =useSelector((state)=>state.ecalc.DeductSchoolFee??0);
    const DeductAvgMedicalExp   =useSelector((state)=>state.ecalc.DeductAvgMedicalExp??0);
    const DeductMaintPaid =useSelector((state)=>state.ecalc.DeductMaintPaid??0);
    const DeductPersonalAllowance =useSelector((state)=>state.ecalc.DeductPersonalAllowance);

    const HouseholdIncomeTotal =useSelector((state)=>state.ecalc.householdIncomeTotal);


const calculateDeduct = (hOutOfRange)=>{
  // console.log('start calculateDeduct');
  // console.log('DeductRentMortgage:',DeductRentMortgage);
  // console.log('DeductRatesGovRent:',DeductRatesGovRent);
  // console.log('DeductMgtFee:',DeductMgtFee);
  // console.log('HouseholdIncomeTotal'+':',HouseholdIncomeTotal);
  // console.log('DeductSalariesTax'+':',DeductSalariesTax);
  // console.log('DeductMPF'+':',DeductMPF);
  // console.log('DeductChildCareExp'+':',DeductChildCareExp);
  // console.log('DeductSchoolFee'+':',DeductSchoolFee);
  // console.log('DeductAvgMedicalExp'+':',DeductAvgMedicalExp);
  // console.log('DeductMaintPaid'+':',DeductMaintPaid);
  // console.log('DeductPersonalAllowance'+':',DeductPersonalAllowance);


  var MAX_INTEGER = 922337203685477;

  var SubTotal1 = 0;
  SubTotal1 = DeductRentMortgage+DeductRatesGovRent+DeductMgtFee;
  var HouseholdIncomeTotal_half = Math.ceil( HouseholdIncomeTotal/2.0);
  if(SubTotal1 > HouseholdIncomeTotal_half){
    SubTotal1 = HouseholdIncomeTotal_half
  }
  
  var SubTotal2 = 0;
  SubTotal2 = DeductSalariesTax+DeductMPF + DeductChildCareExp+DeductSchoolFee + DeductAvgMedicalExp + DeductMaintPaid + DeductPersonalAllowance;
  
  dispatch(setSubTotal2(SubTotal2));

  var GrantTotal = SubTotal1 + SubTotal2;
  if(GrantTotal > MAX_INTEGER){
    hOutOfRange();
    //setDiagTitle("");
    //setDiagContent(props.i18n.t('msg_currency_out_of_range'));
    //setOpen(true);        
  }
  // console.log('SubTotal1:',SubTotal1);
  // console.log('SubTotal2:',SubTotal2);
  // console.log('GrantTotal:',GrantTotal);

  dispatch(setSubTotal1(SubTotal1));
  dispatch(setDeductGrandTotal(GrantTotal));
  dispatch(setDeductTotal(GrantTotal));
}
const isDrug = null;
  if(agreeChecked !== true){
    return (
      <Grid container > 
          <Grid item xs={1} >
          </Grid>
          <Grid item xs={10} >
            <ECalLandingDesktop calculateDeduct={calculateDeduct}  onAgree={(_refno)=>{
              setRefno(_refno);
              setAgreeChecked(true);
              setEnabledStep(1);
     
              const householdIncomeAssets = [
                {
                  id:0,
                  patient_rel:'Patient',
                  
                  salary:0,
                  allowance:0,
                  double_pay:0,
                  benefit_in_kind:0,
                  pension:0,
                  rental_income:0,
                  sponsor:0,
                  other_income:0,
                  subTotal:0,

                  cash:0,
                  saving:0,
                  investment:0,
                  insurance:0,
                  property:0,
                  carpark:0,
                  land:0,
                  other:0,
                  subTotal2:0,
                }
              ]; 
              dispatch(setState({isDrug ,_CCFMD,recurrentApp,EstimatedItemCost,householdIncomeTotal,householdAssetTotal,householdIncomeAssets,householdMemberCount,DeductTotal,DeductPersonalAllowance,
                refNo:_refno}));
                dispatch(setSubTotal2(0));
                dispatch(setDeductRentMortgage(0));
                dispatch(setDeductRatesGovRent(0));
                dispatch(setDeductMgtFee(0));
                dispatch(setDeductSalariesTax(0));
                dispatch(setDeductMPF(0));
                dispatch(setDeductChildCareExp(0));
                dispatch(setDeductSchoolFee(0));
                dispatch(setDeductAvgMedicalExp(0));
                dispatch(setDeductMaintPaid(0));
                dispatch(setDeductPersonalAllowance(0));
                dispatch(setInit(true));
                dispatch(setInit2(true));
                // console.log("onAgree:",{isDrug ,_CCFMD,recurrentApp,EstimatedItemCost,householdIncomeTotal,householdAssetTotal,householdIncomeAssets,householdMemberCount,DeductTotal,DeductPersonalAllowance,
                //   refNo:_refno})

            }}  i18n={i18n}/>    
          </Grid>
          <Grid item xs={1} >
          </Grid>
        </Grid>
    )}else{
      return (
        <Grid container > 
          <Grid item xs={1} >
          </Grid>
          <Grid item xs={10} >
            <ECalApplicationFormDesktop calculateDeduct={calculateDeduct}  lang={lang} refno={refno} enabledStep={enabledStep} onNext={(step)=>{setEnabledStep(step)}} onReset={()=>{              
              const DeductTotal =0;
              const householdAssetTotal =0;
              const householdIncomeTotal=0;
              const householdMemberCount = 1;
              const EstimatedItemCost=0;
         
              const householdIncomeAssets = [
                {
                  id:0,
                  patient_rel:'Patient',
                  
                  salary:0,
                  allowance:0,
                  double_pay:0,
                  benefit_in_kind:0,
                  pension:0,
                  rental_income:0,
                  sponsor:0,
                  other_income:0,
                  subTotal:0,

                  cash:0,
                  saving:0,
                  investment:0,
                  insurance:0,
                  property:0,
                  carpark:0,
                  land:0,
                  other:0,
                  subTotal2:0,
                }
              ];
              const recurrentApp = null;
              const _CCFMD = null;
              dispatch(setState({isDrug ,_CCFMD,recurrentApp,EstimatedItemCost,householdIncomeTotal,householdAssetTotal,householdIncomeAssets,householdMemberCount,DeductTotal,DeductPersonalAllowance,
              refNo:refno}));
              dispatch(setSubTotal2(0));
              dispatch(setDeductRentMortgage(0));
              dispatch(setDeductRatesGovRent(0));
              dispatch(setDeductMgtFee(0));
              dispatch(setDeductSalariesTax(0));
              dispatch(setDeductMPF(0));
              dispatch(setDeductChildCareExp(0));
              dispatch(setDeductSchoolFee(0));
              dispatch(setDeductAvgMedicalExp(0));
              dispatch(setDeductMaintPaid(0));
              //dispatch(setDeductPersonalAllowance(0));
             }} i18n={i18n}/>
          </Grid>
          <Grid item xs={1} >
          </Grid>
        </Grid>
      )
    };
}


function App() {  

  return(
    <Provider store={store}>
      {(isMobile())?<AppBody />:<AppBodyDesktop  />}        
    </Provider>
  )
}

export default App;
