import React, { useEffect, useState } from 'react';
import { Grid, Stack, TextField } from '@mui/material';
import { NumericFormat } from 'react-number-format';

const ECalInputTextFieldDesktop = (props) => {
    const [text, setText] = useState(props.inputVal);
    useEffect(() => setText(props.inputVal), [props.inputVal]);

    const isAllowed = (values) => {
        const { floatValue, value } = values;
        const numericRegex = /^[0-9]+$/;
        const isEmpty = floatValue === undefined;
        const isPositive = floatValue >= 0;
        const isNumeric = numericRegex.test(value);
        return isEmpty || (isPositive && isNumeric);
    }

    const onBlur = (event) => {
        if (event.target.value === '') {
            setText(0);
            props.onTextChange(0);
        } else {
            props.onTextChange(text);
        }
    }

    const onFocus = (event) => {
        const numericOnlyRegex = /\D/g;
        const newValue = event.target.value.replace(numericOnlyRegex, '');
        if (parseInt(newValue) === 0) {
            setText('');
        }
    }

    const height = props.isLargeCell ? { height: '8.65rem' } : null;
    const backgroundColor = props.isGreyBackground ? { backgroundColor: 'grey.200' } : null;

    return (
        <Grid item xs={12} sx={{ ...backgroundColor, ...height }}>
            <Stack direction="row" alignItems="center" justifyContent="center">
                <NumericFormat
                    customInput={TextField}
                    thousandSeparator
                    prefix="$"
                    value={text}
                    onValueChange={(values) => setText(values.value)}
                    isAllowed={isAllowed}
                    onBlur={onBlur}
                    onFocus={onFocus}
                    variant="outlined" align="rignt"
                    sx={{ p: 1, input: { textAlign: "right", backgroundColor: '#FFF', fontSize: "1.5rem" } }}
                    inputProps={{
                        sx: { py: 0 }, maxLength: 12
                    }}
                />
            </Stack>
        </Grid>
    );
}

export default ECalInputTextFieldDesktop;