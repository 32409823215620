import React, { useEffect, useState } from 'react';
import { Grid, IconButton, Stack, TextField, Typography } from '@mui/material';
import { NumericFormat } from 'react-number-format';

const ECalInputTextFieldMobile = (props) => {
    const [text, setText] = useState(props.inputVal);
    useEffect(() => setText(props.inputVal), [props.inputVal]);

    const isAllowed = (values) => {
        const { floatValue, value } = values;
        const numericRegex = /^[0-9]+$/;
        const isEmpty = floatValue === undefined;
        const isPositive = floatValue >= 0;
        const isNumeric = numericRegex.test(value);
        return isEmpty || (isPositive && isNumeric);
    }

    const onBlur = (event) => {
        if (event.target.value === '') {
            setText(0);
            props.onTextChange(0);
        } else {
            props.onTextChange(text);
        }
    }

    const onFocus = (event) => {
        const numericOnlyRegex = /\D/g;
        const newValue = event.target.value.replace(numericOnlyRegex, '');
        if (parseInt(newValue) === 0) {
            setText('');
        }
    }

    const backgroundColor = props.isGreyBackground ? { backgroundColor: 'grey.200' } : null;
    const iconButton = <IconButton onClick={() => props.onHelpClick()}>
        <img src="images/question_mark.png" />
    </IconButton>;
    return (
        <>
            <Grid item xs={8} sx={{ ...backgroundColor }}>
                <Typography variant="body" display="inline">{props.itemName}
                    {/* {iconButton} */}
                </Typography>
            </Grid>
            <Grid item xs={4} sx={{ ...backgroundColor }}>
                <Stack direction="row" alignItems="center" justifyContent="center">
                    <NumericFormat
                        disabled={props.disabled}
                        customInput={TextField}
                        thousandSeparator
                        prefix="$"
                        value={text}
                        onValueChange={(values) => setText(values.value)}
                        isAllowed={isAllowed}
                        onBlur={onBlur}
                        onFocus={onFocus}
                        variant="outlined" align="rignt"
                        sx={{ p: 0.5, input: { textAlign: "right", backgroundColor: '#FFF', fontSize: "1rem" } }}
                        inputProps={{
                            sx: { py: 0.5 }
                        }}
                    />
                </Stack>
            </Grid>
        </>
    );
}

export default ECalInputTextFieldMobile;