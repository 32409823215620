import * as React from 'react';
import {Stack,Checkbox,Typography, Grid, Link ,Box, Button,Dialog, DialogActions,DialogContent,DialogContentText,DialogTitle,} from '@mui/material';
import axios from 'axios';
import moment from 'moment';
import {useSelector, useDispatch} from 'react-redux';
import { setLang} from '../store/eCalcStore.js';
import {isMobile,useEffectOnce} from '../common/common-lib.js';
import querySearch from "stringquery";
import packageInfo from '../../package.json';

//const APIHost = 'http://160.1.43.61:8080/ews-ecal-api-slt/ecalc';
const APIHost = window.APIHost//'http://localhost:3100';
var query = querySearch(window.location.search);

function ECalLandingHeader(props){
  const dispatch = useDispatch()
  const lang = useSelector((state)=> state.config.lang);
  const [mFontsize,setMFontsize ] = React.useState("medium");

  useEffectOnce(()=>{  
    setMFontsize("medium");
    props.onFontChange("70%");

    if(query["lang"]==="tc"||query["lang"]==="sc"||query["lang"]==="en")
      dispatch(setLang(query["lang"] ))
  });

  let scView =()=>{
    return (
      <>
              <Link href="#" onClick={()=>{dispatch(setLang("tc"))}} underline="none" color="grey.500">
              繁體
              </Link> 
              <Link href="#" onClick={()=>{dispatch(setLang("en"))}} underline="none" color="grey.500">
              English
              </Link>
      </>
    )  
  }
  let tcView =()=>{
    return (
      <>      
              <Link href="#" onClick={()=>{dispatch(setLang("sc"))}} underline="none" color="grey.500">
              简体
              </Link>
              <Link href="#" onClick={()=>{dispatch(setLang("en"))}} underline="none" color="grey.500">
              English
              </Link>
      </>
    )  
  }
  let enView =()=>{
  return (
    <>
            <Link href="#" onClick={()=>{dispatch(setLang("tc"))}} underline="none" color="grey.500">
            繁體
            </Link> 
            <Link href="#" onClick={()=>{dispatch(setLang("sc"))}} underline="none" color="grey.500">
            简体
            </Link>
    </>
  )  
  }
  let langSelect = ()=>{
    if(lang ==="en") { return enView() }
    if(lang ==="tc") { return tcView() }
    if(lang ==="sc") { return scView() }
  }

    return (<>
      <Grid container  spacing={2} >
      <Grid item xs={3}>  
          <Link href="#" onClick={()=>{ 
                      let url = props.i18n.t("header_ha_link")
                      window.open(url);
                    }} >
            <Box
              component="img"
              sx={{
                width: "100%",
                maxWidth: { xs: 350, md: 270 },
              }}
        
              src="/images/ha_logo.gif"
            /> 
          </Link>
      </Grid>
      <Grid item xs={9}  >
        <Stack  justifyContent="center" spacing={1}> 
            <Stack direction="row" justifyContent="right" spacing={3} sx={{p:2}}>
                <Stack direction="row" justifyContent="center" spacing={1} >
                    <Typography color="grey.500"> {props.i18n.t("font_size")} </Typography>
                    <Link href="#" onClick={()=>{
                      setMFontsize("small")
                      props.onFontChange("60%");
                      //document.getElementById("root").style.fontSize = "small";
                      }} underline="none">
                      {mFontsize==="small"?<img src='/images/btn_as_on.jpg' border='0' height='18px' width='18px'/> :<img src='/images/btn_as.jpg' border='0' height='18px' width='18px'/> }
                    </Link>
                    <Link href="#" onClick={()=>{
                      setMFontsize("medium");
                      props.onFontChange("70%");
                      //document.getElementById("root").style.fontSize = "medium";
                      }} underline="none">
                      {mFontsize==="medium"?<img src='/images/btn_am_on.jpg' border='0' height='18px' width='18px'/>:<img src='/images/btn_am.jpg' border='0' height='18px' width='18px'/>}
                    </Link>
                    <Link href="#" onClick={()=>{
                      setMFontsize("large");
                      props.onFontChange("80%");
                      //document.getElementById("root").style.fontSize = "large";
                    }} underline="none">
                      {mFontsize==="large"?<img src='/images/btn_al_on.jpg' border='0' height='18px' width='18px'/>:<img src='/images/btn_al.jpg' border='0' height='18px' width='18px'/>}
                    </Link>              
                </Stack>
                
                {langSelect()}
                <Link href="#" onClick={()=>{}} underline="none" color="grey.500"  dangerouslySetInnerHTML={{__html:props.i18n.t("contact_us")}}>
                </Link>
              </Stack>
              <Box  sx={{backgroundImage :'url(/images/red_title.jpg)',backgroundSize:"100% 65px",margin: 2, p:2}}><Typography variant="h5" color="grey.50"> {props.i18n.t("web_title")}</Typography></Box>
        </Stack>    
      </Grid>
    </Grid>
    
    </>
    );
  }  
function ECalLandingBody(props){
    const [checked,setChecked]=React.useState(false);
    const [loading,setLoading]=React.useState(false);   
  
  return(
    <Box sx={{m:1,p:1 }}>
        <Box  sx={{border: 2}}>
        <Grid container  spacing={2}> 
          <Grid item xs={12} > 
              <Typography  paragraph variant="h6" > </Typography>              
              <Typography   variant="h4" align="center" > {props.i18n.t("disclaimer_title")}  </Typography>
          </Grid> 
          <Grid item xs={12} sx={{ m:1}} > 
            <Box sx={{p:3}} >          
              <Stack direction="row" gap={1}><li/><Typography  component={'span'} variant="h6" align="left"><div dangerouslySetInnerHTML={{__html:props.i18n.t("disclaimer_li1")}}/></Typography></Stack>          
              <Stack direction="row" gap={1}><li/><Typography  variant="h6" align="left">{props.i18n.t("disclaimer_li2")}</Typography></Stack>
              <Stack direction="row" gap={1}><li/><Typography  variant="h6" align="left">{props.i18n.t("disclaimer_li3")}</Typography></Stack>
              <Stack direction="row" gap={1}><li/><Typography  component={'span'} variant="h6" align="left"><div dangerouslySetInnerHTML={{__html:props.i18n.t("disclaimer_li4")}}/></Typography></Stack>
              <Stack direction="row" gap={1}><li/><Typography  component={'span'} variant="h6" align="left"><div dangerouslySetInnerHTML={{__html:props.i18n.t("disclaimer_li5")}}/></Typography></Stack>            
              <Stack direction="row" gap={1}><li/><Typography   variant="h6" align="left">{props.i18n.t("disclaimer_li6")}</Typography></Stack>
              <Stack direction="row" gap={1}><li/><Typography  paragraph variant="h6" align="left">{props.i18n.t("disclaimer_li7")}</Typography></Stack>
            </Box>
            <Box sx={{px:7}} >          
              <Typography   variant="h6" align="left">*{props.i18n.t("disclaimer_remarks_ued")}</Typography>
              <Typography  paragraph variant="h6" align="left">^{props.i18n.t("disclaimer_remarks")}</Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sx={{ m:1}}  > 
            <Stack direction="row" gap={1} justifyContent="center"> <Checkbox onChange={(ev)=>{ setChecked(ev.target.checked)}}/><Typography  variant="h5" >{props.i18n.t("disclaimer_checkbox")}</Typography></Stack>
          </Grid>
          <Grid item xs={12} sx={{ m:1}}  > 
            <Stack direction="row" gap={1} justifyContent="center"><Button variant="contained" disabled={!checked} loading={{loading}} onClick={()=>{ setLoading(true);props.onAgree()}}> {props.i18n.t("disclaimer_button")} </Button></Stack>
          </Grid>
          <Grid item xs={12} sx={{ m:1}}  > </Grid>
        </Grid>
      </Box>
    </Box>
  )
  
  }
function ECalLanding(props){
  const [diagTitle, setDiagTitle] = React.useState( "");    
  const [diagContent, setDiagContent] = React.useState( "Cannot connect server" );
  const [open, setOpen] = React.useState(false);
  const lang = useSelector((state)=> state.config.lang);
  const handleClickOpen = (helpTipsTitleKey,helpTipsKey) => {
      setDiagTitle(props.i18n.t(helpTipsTitleKey));
      setDiagContent(props.i18n.t(helpTipsKey));
      setOpen(true);
    };
  const handleClose = () => {
    setOpen(false);
  }; 
    return ( 
    <>
      <ECalLandingHeader i18n={props.i18n} onFontChange={(fontsize)=>{document.getElementById("root").style.zoom =fontsize}} />
      <ECalLandingBody onAgree={(ev)=>{
        let mlang = "zh-HK";
        if(lang === "en") 
          mlang = "en-US";
        if(lang === "sc") 
          mlang = "zh-CN";

          
        axios({
                method: 'post',
                url: APIHost+'/GetReferenceNum',
                data: {
                  "appDtm":moment().format("YYYY-MM-DD HH:mm:ss"),
                  "refNo":"",
                  "lang":mlang,    
                  "ipAddress":"",
                  "browser":"",
                  "isMobileDevice":isMobile(),
                  "userAgent":"",
                  "screenWidth":window.innerWidth,
                  "screenHeight":window.innerHeight,
                },
               
              }).then((resp)=>{
                // console.log(resp.data);
                props.onAgree(resp.data.result.refNo);
              }).catch((err)=>{
                console.log(err);
                setDiagTitle("Connection error");            
                setDiagContent(err.toString());
                setOpen(true);
                //props.onAgree(0);
              })
      }} i18n={props.i18n}/>
      <ECalLandingFooter i18n={props.i18n} version={packageInfo.version}/>
      <Dialog
          open={open}
          onClose={handleClose}
          fullwidth
        >
          <DialogTitle >
            {diagTitle}
          </DialogTitle>
          <DialogContent>
            <DialogContentText >
              {diagContent}
            </DialogContentText>
          </DialogContent>
          <DialogActions>          
            <Button onClick={handleClose} autoFocus>
            {props.i18n.t("tooltip_close")}
            </Button>
          </DialogActions>
        </Dialog>
    </>
    );
  }
function ECalLandingFooter(props){
    return(
      <>
      <Stack direction="row"  spacing={2}>
          <Typography component={'span'} variant="h6" color="grey.500" > <div dangerouslySetInnerHTML={{__html:props.i18n.t("footer_ha_copyright").replace('{0}',moment().format("YYYY"))}} />  </Typography>
          <Link href="#" onClick={()=>{ 
              let url = props.i18n.t("footer_allrightreserve_link")
              window.open(url, '__blank');
            }} >
              <Typography variant="h6" color="grey.500">{props.i18n.t("footer_allrightreserve")}</Typography>
          </Link> 
          <Link href="#" onClick={()=>{ 
                let url = props.i18n.t("footer_privacy_link")
                window.open(url, '__blank');
              }} >
            <Typography variant="h6" color="grey.500">{props.i18n.t("footer_privacy")}</Typography>
          </Link> 
          <Link href="#" onClick={()=>{ 
                    let url = props.i18n.t("footer_linkpolicy_link")
                    window.open(url, '__blank');
                  }} >
                <Typography variant="h6" color="grey.500">{props.i18n.t("footer_linkpolicy")}</Typography> 
            </Link> 
            <Link href="#" onClick={()=>{ 
                      let url = props.i18n.t("footer_disclaimer_link")
                      window.open(url, '__blank');
                    }} >
            <Typography variant="h6" color="grey.500">{props.i18n.t("footer_disclaimer")}</Typography> 
          </Link> 
          <Link href="#" onClick={()=>{ 
                      let url = props.i18n.t("footer_termofuse_link")
                      window.open(url, '__blank');
                    }} >
            <Typography variant="h6" color="grey.500">{props.i18n.t("footer_termofuse")}</Typography>
          </Link>  
      </Stack>
           
      <Typography variant="h6" color="grey.500">{props.i18n.t("footer_platform")}</Typography>
      <Grid container sx={{ justifyContent: "flex-end" }}>
          <Typography variant="body2" color="grey.500">{props.version}</Typography>
      </Grid>
      </>
    )
  
  }
export {ECalLandingFooter as ECalLandingFooterDesktop,ECalLandingBody as ECalLandingBodyDesktop,ECalLandingHeader as ECalLandingHeaderDesktop,ECalLanding as ECalLandingDesktop}